.colored{
    font-size: 4rem;
    color: #D89666;
    font-weight: bolder;
}
p{
    color:#D89666;;
}
title p{
    margin: 0px;
    font-size: 7rem;
    font-weight: bolder;
}
.not-colored{
    
    font-size: 4.6rem;
    -webkit-text-stroke: 0.4px #D89666;
    -webkit-text-fill-color: transparent;
    opacity: 1;
    font-weight: bolder;
    text-align: center;
    /* font-family: 'Ogg'; */
    /* text-decoration: .5px line-through; */
      
}

.about{
    
    margin-left:20px ;
    /* margin-bottom: 40px; */
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.about .title{
    display: flex;
    flex-direction: column;
    align-items: center;
}
#about{
    /* margin-top: 40px; */
    display: flex;
    flex-direction: column;
    align-items: center;
}
#about p{
    padding-top: 20px;
    text-align: center; 
}

@media(min-width:900px){
    #about{
      flex-direction: row;
      
  }
  #about p{
    padding: 10px;
}
.not-colored{
    font-size: 6rem;
}
  }

