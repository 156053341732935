#cursor {
  --size: 50px;
  position: fixed;
  top: 0;
  left: 0;
  height: var(--size);
  width: var(--size);
  background-color: transparent;
  border: 1px solid #d89666;
  border-radius: 50%;
  transform: translate3d(
    calc(100vw * var(--cursor-x) - var(--size) / 2),
    calc(100vh * var(--cursor-y) - var(--size) / 2),
    0
  );
  pointer-events: none;
  z-index: 111;
  transition: scale 0.2s ease-out;
  will-change: transform;
  animation-name: var(--cursor-animation);
  animation-duration: 0.5s;
  animation-fill-mode: forwards;
}

@keyframes click {
  0% {
    transform: translate3d(
        calc(100vw * var(--cursor-x) - var(--size) / 2),
        calc(100vh * var(--cursor-y) - var(--size) / 2),
        0
      )
      scale(1);
  }

  50% {
    transform: translate3d(
        calc(100vw * var(--cursor-x) - var(--size) / 2),
        calc(100vh * var(--cursor-y) - var(--size) / 2),
        0
      )
      scale(2);
  }

  100% {
    transform: translate3d(
        calc(100vw * var(--cursor-x) - var(--size) / 2),
        calc(100vh * var(--cursor-y) - var(--size) / 2),
        0
      )
      scale(1);
  }
}

@media (max-width:600px){
  #cursor{
    display: none;
  }
}





/* .cursor {
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%);
    border-radius: 50%;
    background: transparent;
    pointer-events: none;
    z-index: 111;
    border: 1px solid #d89666;;
    transition: all 0.2s ease-out;
    animation: moveCursor1 .5s infinite alternate;
}

.expand {
    background: transparent;
    animation: moveCursor2 .5s forwards;
    border: 1px solid #d89666;
}

@keyframes moveCursor1 {
    from {
        transform: scale(1);
    }

    to {
        transform: scale(.8);
    }
}

@keyframes moveCursor2 {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(2);
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

   */
