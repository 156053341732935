.Header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
    padding-right: 10px;

}
.logo{
    position: fixed;
    background-image: url("../../assets/img/logo.svg");
    height: 40px;
    width: 40px;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 999;
  }
  .menu{
    width: 40px;
  }
.menu-hamburger {
    position: fixed;
    z-index: 999;
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: flex;
    padding-right: 0;

  }
  .line {
    fill: none;
    stroke:#D89666;
    stroke-width: 6;
    transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
      stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  .line1 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .line2 {
    stroke-dasharray: 60 60;
    stroke-width: 6;
  }
  .line3 {
    stroke-dasharray: 60 207;
    stroke-width: 6;
  }
  .opened .line1 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }
  .opened .line2 {
    stroke-dasharray: 1 60;
    stroke-dashoffset: -30;
    stroke-width: 6;
  }
  .opened .line3 {
    stroke-dasharray: 90 207;
    stroke-dashoffset: -134;
    stroke-width: 6;
  }


  /* menu */
  .navigation {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    background: #4e4e4e;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  
  .menu-list {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .menu-item {
    margin: 0 10px;
    font-size: 5rem;
    
  }
  
  .menu-item a {
    text-decoration: none;
    color: #000;
    transition: color 0.3s, font-size 0.6s;

  }
  

  /* transition */
  
  .menu-item a:hover {
    color:#D89666; /* Cambia il colore del testo durante l'hover */
    font-size: 6rem; /* Ingrandisci il testo durante l'hover */
  }
  