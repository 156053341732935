/* *{
    font-family: 'Ogg';
} */

.home{
    display: flex;
    flex-direction: column;
    overflow: hidden;
}
.home .welcome{

  padding:40px 0px 0px 10px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}
.intro {
    /* margin-top: 5rem; */
    color: #D89666;
    width: 80%;
    margin-left:20px ;
    font-weight: bolder;
  }
  p{margin: 0;}
  .fill {
    font-size: 6rem;
    font-weight: bolder;
    opacity: 0;
    position: relative;
  }
  
  .stroke {
    font-size: 4.5rem;
    -webkit-text-stroke: 0.5px #D89666;
    -webkit-text-fill-color: transparent;
    opacity: 0;
    font-weight: bold;
    line-height: 4rem;
  }
  
  .one {
    animation: revealText 1.5s forwards;
    animation-delay: 0.5s;
  }
  
  .two {
    animation: revealText 1.5s forwards;
    animation-delay: 2.0s;
  }
  
  .three {
    animation: revealText 1.5s forwards;
    animation-delay: 3.0s;
  }
  #arrow-down{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  @keyframes revealText {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .about{
    justify-content: space-evenly;
  }

  @media(min-width:900px){
   .fill{
    font-size: 8rem;
   }
   .home{
   margin-top: 0px;
   }
   
  .stroke {
    font-size: 5rem;
  }
 
    
}
