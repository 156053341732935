#contact-form{
    /* position: relative;
    z-index: 100; */
    /* display: flex;
    flex-direction: column; */
    width: 100%;
    display: flex;
    flex-direction: column;
}

#contact-form div{
    color: #d89666;  
}
#contact-form div{
    padding-top: 20px;
}

#contact-form input,#contact-form textarea{

   color: #d89666;
    background: none;
   background-color: none;
   border: none;
   border-bottom: 1px solid #d89666;
    height: 40px;;
    width: 80vw;
    max-width: 600px;
}
.contact-page-container h1{
    margin-bottom: 0px;
    text-align: center;
    word-spacing: 10px;
    /* letter-spacing: 80%; */
    font-size: 40px;
    font-weight: bolder;
    font-family: 'Roboto';
    color: #d89666;
    /* -webkit-text-stroke: 0.5px #d89666; */
      /* -webkit-text-fill-color: transparent; */
      
}


#contact-form button{
    width: 100px;
    height: 40px;
    border-radius: 20px;
    background: none;
    color:#d89666;
    border-color: #d89666;
    margin:20px  0px ;
}

#contact-form input[type=text]:focus {
    border: none
  }