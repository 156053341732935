.project-and-work{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin-left: 10%;
  margin-top: 100px;
  margin-bottom: 100px;
  flex-direction: column;
  height: 30vh;
  padding-bottom: 100px;
  border-bottom: 1px dashed #d89666;
}
.project-and-work .h1-project{
  font-size: 5rem;
  -webkit-text-stroke: 0.5px #D89666;
  -webkit-text-fill-color: transparent;
  opacity: 1;
  font-weight: bolder;
  text-align: center;
  /* font-family: 'Ogg'; */
  /* text-decoration: .5px line-through; */

}





.my-projects{
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 5vw;
  margin-right: 5vw;
  height: 50vh;
  border-bottom: 1px dashed #d89666;
}
.my-projects h1{
  /* font-family: 'Ogg'; */
  font-size: 8rem;
  font-weight: lighter;
  line-height: 80%;
  text-transform: capitalize;
}
.project-page a {
  color: #D89666;
  text-decoration: none;
}
.project-page-container{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: fit-content;
}
.project-page-items{
  display: flex;
  justify-content: center;
  height: fit-content;
  flex-direction: row;
  width: 100%;
  flex-wrap: wrap;
}
.project-page-item{
  padding: 10px;
  width: 400px;
  transition: transform .6s cubic-bezier(.16,1,.3,1);

}
.project-page-item:hover{
  transform: scale(1.05);
}
.project-page-item-number p{
  font-size: 10px;
  font-weight: 500;
  font-family: 'Inter';
}
.project-page-item-header{
  display: flex;
  width: 100%;
  align-items: baseline;
  justify-content: space-between;
}
.project-page-item-header h1{
  font-size: 15px;
  font-weight: bold;
  width: 50%;
  font-family: 'Inter';
  text-transform: uppercase;
}
.project-page-item-header p{
  font-size: 10px;
  font-weight: 500;
  /* font-family: 'Inter'; */
  width: 50%;
  text-transform: uppercase;
}
.project-page-item-image{
  width: 100%;
  height: 400px;
  background-color: #d89666;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  margin-top: 10px;
}
.project-page-item-footer{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-top: 10px;
  border-top: 1px dashed #d89666;
  border-bottom: 1px dashed #d89666;
  padding: 10px 0px;
}
.project-page-item-footer p{
  font-size: 12px;
  font-weight: 400;
  /* font-family: 'Inter'; */
  text-transform: uppercase;
}
.project-page-item-footer img{
  width: 12px;
  margin-right: 5px;
  transform: rotate(270deg);
  
  
}

/* Opacita Immagini ---------------------------------- */
.project-page-item-image{
  opacity: 0.8  ;
}
.project-page-item-image:hover{
  opacity: 1;
}
.project-page-item-image1{
background-image: url(../../assets/img/ParliamoDiThe.png);   
}
.project-page-item-image2{
background-image: url(../../assets/img/JobCreation.png);   
}
.project-page-item-image3{
background-image: url(../../assets/img/GoodMorning.png);   
}
.project-page-item-image4{
background-image: url(../../assets/img/Metaprogetto.png);   
}
.project-page-item-image5{
background-image: url(../../assets/img/FlyTrack.png);   
}
.project-page-item-image6{
background-image: url(../../assets/img/Avatar.png);   
}






@media(min-width:900px){
  .project-and-work h1 .h1-project{
    font-size: 6rem;
  }
  .project-page-item{
    padding: 50px;
  }
  }











/* .projects{
    min-height: 100vh;
    color: #D89666;

}
.project-and-work{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80%;
    margin-left: 10%;
    margin-top: 100px;
    margin-bottom: 100px;
    flex-direction: column;
    height: 30vh;
    padding-bottom: 100px;
    border-bottom: 1px dashed #D89666;;
}
.project-and-work h1 .h1-project{
    text-decoration: none;
    color:#D89666;
    font-size: 4rem;
    font-weight: lighter;
    font-family: 'Ogg';
    text-decoration: .5px line-through;

}
.project-and-work p{
    color:#D89666;
    font-size: 20px;
    margin-top: -20px;
    font-weight: lighter;
    font-family: 'Inter';
}

/* projects 
  .container-projects {
    margin-top:4rem ;
    border-bottom: 1px solid #D89666;

  }
  
  .change-view {
    display: flex;
    flex-direction: row;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .option {
    position: relative;
    padding: 10px;
    margin-right: 10px;
    cursor: none;
    border: none;
    background-color: transparent;
  }
  
  .option::before {
    content: "";
    position: absolute;
    left: 0;
    bottom: 7px;
    width: 0;
    height: 1px;
    background-color:#D89666;
    transition: width 0.3s ease-in-out;
  }
  
  #list-view .project {
    border-bottom: 1px solid #D89666;
    padding: 2rem;
  }
  
  .content-list .project {
    border-bottom: none;    
  }

  #grid-view,
  #view,
  .grid-view {
    display: none;
  }
  #list-view {
    display: block;
  }
  ul .grid-project{
    display: inline;
  }


  

















@media(min-width:900px){
    .project-and-work h1 .h1-project{
        font-size: 6rem;
    }
    .content-list {
        display: none;
       }
       
       .grid-view {
           display: grid;
           grid-template-columns: repeat(3, 1fr);
           grid-template-rows: repeat(3, 1fr);
           gap: 30px;
         }
         
         #grid-view .project {
           border: 1px solid #D89666;
           padding: 10px;
           height: 300px;
         }
         #list-view .project {
           border-bottom: 1px solid #D89666;
           padding: 2rem;
         }
         
         .content-list .project {
           border-bottom: none;    
         }
       
       
         #grid-view .b4{
           border: 6px solid;
         }
         
         .grid-view {
           display: grid;
         }
         #grid-view,
         #view{
            display: block;
         }
} */
